:root {
  --primary-color: #044a42;
  --secondary-color: #b8e1dd;
  --third-color: #3a918a;
  --fourth-color: #072925;
  /* --box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3); */
}

body {
  margin: 0 auto;
  max-width: 2000px;
  /* background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%); */
}

.events-hero {
  display: flex;
  flex-direction: column;
  background-image: url("../images/mistyarnospark.jpeg");
  height: 80vh;
  align-self: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* width: 100vw;
        height: 100vh; */
}
.events-hero-title-container {
  /* background-color: #044a42; */
  padding: 10px;
  border-radius: 30px;
}
.events-hero-title {
  color: white;
}
.events-hero-content {
  position: relative;

  text-align: center;
}

/* #hero-button {
      color: white;
      padding: 5px;
      border-radius: 5px;
      background-color: #044a42;
      margin-top: 10px;
    }
    
    #hero-button:hover {
      cursor: pointer;
      background-color: #3a9189;
    } */
.center-container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 100vh;
}

.events-poster {
  text-align: center; /* Center the images within the container */
}

.events-poster img {
  max-width: 100%; /* Make sure images don't exceed the container width */
  height: auto; /* Maintain aspect ratio */
  margin: 10px; /* Add some margin between images if needed */
}
@media (max-width: 768px) {
  /* Adjust font sizes for smaller screens */
  h1 {
    font-size: 36px;
  }

  h2,
  h3 {
    font-size: 18px;
  }

  p {
    font-size: 16px;
  }

  /* Adjust padding and margin for smaller screens */
  .row {
    padding-left: 1rem;
  }

  /* Center images for smaller screens */
  .row-image {
    display: block;
    margin: 0 auto;
    align-items: center;
  }

  /* Adjust the size of the hero background image */
  /* .events-hero {
    background-size: contain;
  } */

  /* Other adjustments for smaller screens */
  .events-hero-title-container {
    border-radius: 20px;
  }

  .events-hero-button {
    margin-top: 5px;
  }

  /* Adjust other elements as needed */
}
