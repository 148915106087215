.topnav {
  background-color: #044a42;
  width: 100%;
  padding: 30px;
}

.topnav a {
  float: center;
  color: #f2f2f2;
  text-align: center;
  padding: 1px 1px;
  text-decoration: none;
  font-size: 20px;
}

/* Change the color of links on hover */
.topnav a:hover {
  /* background-color: #b8e1dd; */
  color: black;
  border-radius: 15px;
}

/* Add a color to the active/current link */
.topnav a.active {
  text-decoration: underline;
  color: grey;
  border-radius: 15px;
}

ul {
  list-style: none;
  display: inline;
}

li {
  display: inline;
}
