.donate-hero {
  display: flex;
  flex-direction: column;
  background-image: url("../images/sidebridge.jpeg");
  height: 100vh;
  align-self: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* width: 100vw;
          height: 100vh; */
  margin-bottom: 40px;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.donate-subtitle {
  color: white;
}

.paypal {
  &-logo {
    font-family: Verdana, Tahoma;
    font-weight: bold;
    font-size: 26px;

    i:first-child {
      color: #253b80;
    }

    i:last-child {
      color: #179bd7;
    }
  }

  &-button {
    padding: 15px 30px;
    border: 1px solid #ff9933;
    border-radius: 5px;
    background-image: linear-gradient(#fff0a8, #f9b421);
    margin: 0 auto;
    display: block;
    min-width: 138px;
    position: relative;

    &-title {
      font-size: 14px;
      color: #505050;
      vertical-align: baseline;
      text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.6);
    }

    .paypal-logo {
      display: inline-block;
      text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.6);
      font-size: 20px;
    }
  }
}

.contact-card {
  height: 20vh;
  width: 40%;
  background-color: #044a42;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-card:hover {
  background-color: #3a918a;
}

.contact-card-text {
  color: white;
  font-weight: 600;
}

.contact-card-text > a {
  text-decoration: none;
  color: inherit;
}

.contact-hero-title-container {
  /* background-color: #044a42; */
  padding: 10px;
  border-radius: 30px;
}
.contact-hero-title {
  color: white;
  padding-top: 180px;
}
.contact-hero-content {
  position: relative;

  text-align: center;
}
h4,
h3 {
  margin-top: 40px;
  margin-bottom: 30px;
}

.auction-listings {
  margin-top: 40px;
}

.bidding-section {
  margin: 40px;
}

.contact-cards-container {
  margin-top: 30px;
}
@media (max-width: 768px) {
  /* Adjust font sizes for smaller screens */
  h1 {
    font-size: 36px;
  }

  h2,
  h3 {
    font-size: 18px;
  }

  p {
    font-size: 16px;
  }

  /* Adjust padding and margin for smaller screens */
  .row {
    padding-left: 1rem;
  }

  /* Center images for smaller screens */
  .row-image {
    display: block;
    margin: 0 auto;
    align-items: center;
  }

  /* Adjust the size of the hero background image */
  /* .events-hero {
    background-size: contain;
  } */

  /* Other adjustments for smaller screens */
  .donate-hero-title-container {
    border-radius: 20px;
  }

  #.donate-hero-button {
    margin-top: 5px;
  }

  /* Adjust other elements as needed */
}
