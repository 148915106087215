:root {
  --primary-color: #044a42;
  --secondary-color: #b8e1dd;
  --third-color: #3a918a;
  --fourth-color: #072925;
  /* --box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3); */
}

body {
  margin: 0 auto;
  max-width: 2000px;
  /* background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%); */
}

.contact-hero {
  display: flex;
  flex-direction: column;
  background-image: url("../images/groundforcewomen.jpeg");
  height: 80vh;
  align-self: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* width: 100vw;
        height: 100vh; */
}

.contact-cards-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.contact-card {
  height: 20vh;
  width: 40%;
  background-color: #044a42;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-card:hover {
  background-color: #3a918a;
}

.contact-card-text {
  color: white;
  font-weight: 600;
}

.contact-card-text > a {
  text-decoration: none;
  color: inherit;
}

.contact-hero-title-container {
  /* background-color: #044a42; */
  padding: 10px;
  border-radius: 30px;
}
.contact-hero-title {
  color: white;
  padding-top: 180px;
}
.contact-hero-content {
  position: relative;

  text-align: center;
}

/* #hero-button {
      color: white;
      padding: 5px;
      border-radius: 5px;
      background-color: #044a42;
      margin-top: 10px;
    }
    
    #hero-button:hover {
      cursor: pointer;
      background-color: #3a9189;
    } */
.contact-container {
  padding-left: 20px;
  padding-right: 20px;
}

.contact-container > h2 {
  padding: 20px;
}

.contact-container > p {
  text-align: center;
  padding-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .contact-card-text {
    font-size: 24px; /* Adjust the font size as needed */
  }
}
